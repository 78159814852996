<template>
    <div>
        <div id="loading-overlay" v-if="loadingmsg">
        </div>
        <div id="loading-canvas" align-v="center" v-if="loadingmsg">
                Loading...
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'

    export default {

        computed: {
            ...mapGetters({
                loadingmsg: 'loadingmsg/loadingmsg'
            })
        },

    }
</script>

<style scoped>
    #loading-overlay {
        position: fixed;
        top:0;
        z-index: 1100;
        min-height: calc(100vh - 1.5em);
        min-width: 100%;
        max-width: 100%;
        background-color: white;
        opacity: .90;
        /* transform: translateY(-1.5em); */
    }

    #loading-canvas {
        position: fixed;
        top: 0;
        z-index: 1100;
        margin-top: 3.5em;
        margin-bottom: 1.5em;
        color: black;
        text-align: center;
        padding-top: 30%;
        min-height: 100%;
        max-height: 100%;
        min-width: 100%;
        max-width: 100%;
        font-weight: bold;
        font-size: 2em;

    }
</style>
