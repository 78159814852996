<template>
    <div>
        <div id="force-portrait-overlay" v-if="forceportrait">
        </div>
        <div id="force-portrait-canvas" align-v="center" v-if="forceportrait">
            <div class="main-message">
                Hi! Looks like you are in landscape mode.<br>
                The app is designed for portrait mode only.
                <div class="second-message">
                    Rotate device to dismiss this message.
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'

    export default {

        computed: {
            ...mapGetters({
                forceportrait: 'forceportrait/forceportrait'
            })
        },

    }
</script>

<style scoped>
    #force-portrait-overlay {
        touch-action: none;
        position: fixed;
        top: 0;
        z-index: 1000;
        min-height: 100%;
        max-height: 100%;
        min-width: 100%;
        max-width: 100%;
        background-color: white;
        opacity: .90;
    }

    #force-portrait-canvas {
        position: fixed;
        top: 0;
        z-index: 1000;
        min-height: 100%;
        max-height: 100%;
        min-width: 100%;
        max-width: 100%;

    }

    .main-message {
        margin-top: 2em;
        font-size: 1.5em;
        text-align: center;
        font-weight: bold;
    }

    .second-message {
        margin-top: 1em;
        font-style: italic;
    }
</style>
